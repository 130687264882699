import Link from "next/link";
import classNames from "classnames/bind";
import styles from "./ErrorPage.module.css";

const cx = classNames.bind(styles);

export function ErrorPage({
  title,
  homeButtonLabel,
  homeButtonUrl,
  resetButtonLabel,
  resetButtonOnClick,
}: {
  title: string;
  homeButtonLabel?: string;
  homeButtonUrl?: string;
  resetButtonLabel?: string;
  resetButtonOnClick?: () => void;
}) {
  return (
    <div className={cx("errorpage")}>
      <div className={cx("inner")}>
        <h1 className={cx("title")}>{title}</h1>
        <div className={cx("navigation")}>
          <Link className={cx("link")} href={homeButtonUrl ?? `/`}>
            {homeButtonLabel}
          </Link>
          <button className={cx("link")} onClick={resetButtonOnClick}>
            {resetButtonLabel}
          </button>
        </div>
      </div>
    </div>
  );
}
