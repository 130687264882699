"use client"; // Error components must be Client Components

import { useEffect } from "react";
import { ErrorPage } from "../../components/ErrorPage/ErrorPage";
import { i18nConfig } from "../../i18nConfig";
import { usePathname } from "next/navigation";
import { translations } from "./translations";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  const { locales, defaultLocale } = i18nConfig;
  const pathname = usePathname();

  const locale =
    locales.find((locale) => pathname.startsWith(`/${locale}/`) || pathname === `/${locale}`) ??
    defaultLocale;

  const dictionary = translations[locale];

  return (
    <ErrorPage
      title={dictionary.errorPage.title}
      resetButtonLabel={dictionary.errorPage.resetButtonLabel}
      resetButtonOnClick={() => reset()}
      homeButtonLabel={dictionary.errorPage.homeButtonLabel}
      homeButtonUrl={`/${locale}`}
    />
  );
}
